"use strict"
window.log = require('picolog');
log.info('Picolog is loaded');

// assuming `log` is the picolog object
var orgConsole;
if (typeof console == 'object') {
	orgConsole = console; // hang on to original
	console = log;
	if (console !== log) {
		log.log('Replacing the console is not allowed in this environment');
	}
}
log.level = log.INFO;

// Fix for PARCEL HMR (TEMP SOLUTION)
console.clear = () => {};

/*// Import jQuery
import jQuery from "jquery";
window.$ = jQuery;
window.jQuery = jQuery;
// Import HTMX
import "htmx.org";
window.htmx = require("htmx.org");
// Import GSAP
import { gsap } from "gsap";
import ScrollToPlugin from 'gsap/dist/ScrollToPlugin';
gsap.registerPlugin(ScrollToPlugin);
gsap.install(window);
window.Fancybox = Fancybox;
// Import boostrap collapse
import Collapse from "bootstrap/js/dist/collapse";
window.Collapse = Collapse;
// Import PDFObject
import PDFObject from "pdfobject";
window.Swal = Swal;
// Import Mini Masonry
import MiniMasonry from "minimasonry";
window.MiniMasonry = MiniMasonry;
// Import basicLightbox
import * as basicLightbox from 'basiclightbox'
window.basicLightbox = basicLightbox;*/

// Import Swiper JS
import {Swiper, EffectFade } from "swiper/bundle";
window.Swiper = Swiper;
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/css/effect-fade";
// Import Fancyapps
import { Fancybox } from "@fancyapps/ui";
// Import SweetAlert2
import Swal from 'sweetalert2/dist/sweetalert2.js';
// Import Web worker timer
import { clearInterval, clearTimeout, setInterval, setTimeout } from 'worker-timers';
// Import Utils.js
import { optimizedResize } from "./utils.js";
// Import Tippy.js
import tippy from 'tippy.js';
//import 'tippy.js/dist/tippy.css'; // optional for styling
// Import Progressive Replace JS
import 'progressive-image.js';
// Impo Shoelace components, set base path and get css
import '@shoelace-style/shoelace/dist/components/details/details.js';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path.js';
// Set the base path to the folder you copied Shoelace's assets to
setBasePath('@shoelace-style/shoelace/dist/');
import '@shoelace-style/shoelace/dist/themes/light.css';

// Process SCSS
import '../../httpdocs/assets/scss/procare.scss';

window.PC = {};
PC.el = {};
PC.config = {};
PC.swipers = {};
	
PC.config.portrait = window.matchMedia("(orientation: portrait)");

document.addEventListener("DOMContentLoaded", function() {
	log.info("%cPROCARe website", "color: red; font-weight: bold;");
	PC.el.$body = document.querySelector('body');

	// Remove preload to fix css animation
	setTimeout(() => PC.el.$body.classList.remove("pc-preload"), 250);

	// Main navigation elements
	PC.el.$mainNav = document.getElementById("main_nav");
	PC.el.$mainMenu = document.getElementById("main_menu");	
	PC.el.$mainMenu_Hamburger = document.getElementById("btn_main_menu");

	// Add click event to hamburger menu
	PC.el.$mainMenu_Hamburger.addEventListener("click", (evt) => {
		PC.el.$body.classList.toggle("nav-open");
		//evt.target.setAttribute("aria-expanded", true);
	});
	
	// Find all dropdown menu in the main nav
	PC.el.$mainMenu_Dropdown = PC.el.$mainMenu.getElementsByClassName("has-children");	
	for (const element of PC.el.$mainMenu_Dropdown) {
		//log.info(element);

		let anchor = element.getElementsByTagName("a")[0];
		let submenu = anchor.nextSibling;
		submenu.classList.remove("closed");
		let submenuHeight = submenu.offsetHeight;		
		submenu.classList.add("closed");
		submenu.style.height = submenuHeight+"px";
		submenu.dataset.height = submenuHeight+"px";
		//log.info(submenu.style.height);

		// Add click event filtering mobile version to open submenu
		anchor.addEventListener("click", function(evt) {
			if (PC.config.mobile) {
				evt.preventDefault();
				submenu.classList.toggle("closed");
			}
		});
	}
	/*PC.el.$mainMenu_Dropdown = [].slice.call(PC.el.$mainMenu_Dropdown);
	PC.el.$mainMenu_Dropdown.forEach(element => {
		log.info(element);

		let anchor = element.getElementsByTagName("a")[0];
		let submenu = anchor.nextSibling;
		let submenuHeight = submenu.offsetHeight;
		submenu.style.height = submenuHeight+"px";
		submenu.dataset.height = submenuHeight+"px";
		log.info(submenu.style.height);

		anchor.addEventListener("click", function(evt) {
			if (PC.config.mobile) {
				evt.preventDefault();
				submenu.classList.toggle("closed");
			}
		});
	});*/

	// Actions to execute on specific pages
	// Homepage
	if (document.getElementsByClassName("pc-home").length > 0) {
		// Swiper News
		new Swiper("#swiper_news", {
			loop: true,
			slidesPerView: 1,
      		spaceBetween: 24,
			//effect: "fade",
			autoplay: {
				delay: 3500,
			},
			pagination: {
				el: ".swiper-pagination",
				dynamicBullets: true,
			},
			navigation: false,
			breakpoints: {
				768: {
					slidesPerView: 2,
					spaceBetween: 24,
				}/*,
				992: {
					slidesPerView: 2,
					spaceBetween: 24,
				},*/
			},
		});

		// Swiper Events
		new Swiper("#swiper_events", {
			effect: "fade",
			autoplay: {
				delay: 3500,
			},
			pagination: {
				el: ".swiper-pagination",
				dynamicBullets: true,
			},
			navigation: false,
		});
		//PC.swipers.events.stop();
		//document.getElementById("swiper_events").swiper.stop();
		//setTimeout(() => document.getElementById("swiper_events").swiper.start(), 1000);

		// Swiper Reports
		new Swiper("#swiper_reports", {
			effect: "fade",
			autoplay: {
				delay: 3500,
			},
			pagination: {
				el: ".swiper-pagination",
				dynamicBullets: true,
			},
			navigation: false,
		});
		//PC.swipers.reports.stop();
		//document.getElementById("swiper_reports").swiper.stop();
		//setTimeout(() => document.getElementById("swiper_reports").swiper.start(), 2000);
	}

	// Article section
	if (document.getElementsByClassName("pc-article").length > 0) {
		tippy("[data-tippy-content]", {
			animation: 'scale',
		});

		let tippy_authors = document.querySelectorAll("[data-tippy-template]");

		
		tippy(tippy_authors, {
			content(reference) {
				const id = reference.getAttribute("data-tippy-template");
				const template = document.getElementById(id);
				return template.innerHTML;
			},
			theme: "procare-author",
			allowHTML: true,
			//hideOnClick: false,
			interactive: true,
			trigger: "click",
			animation: "scale",
		});

		/*for (const author of tippy_authors) {
			// Upon clicking, remove all trigger event listeners
			author.addEventListener('click', () => {
				instance.set({ trigger: 'manual' })
			})
		}*/
	
	}

	/**
	 * Actions to perform on page resize
	 */
	function resizeActions() {
		// Screen info
		PC.config.winWidth = (window.innerWidth > 0) ? window.innerWidth : screen.width;
		PC.config.winHeight = (window.innerHeight > 0) ? window.innerHeight : screen.height;
		PC.config.screenWidth = window.screen.width;
		PC.config.screenHeight = window.screen.height;
		PC.config.dpr = (window.devicePixelRatio !== undefined) ? window.devicePixelRatio : 1;
		PC.config.dpr = Math.round(PC.config.dpr * 100) / 100;
		var devinfo = PC.config.winWidth + " x " + PC.config.winHeight + " px - ratio: " + PC.config.dpr + " | " + (PC.config.portrait.matches ? 'portrait' : 'landscape');
		document.getElementById("dev_info").innerText = devinfo;

		if (PC.config.winWidth < 992) {			
			log.info("Mobile");
			// Mobile
			PC.config.mobile = true;
			//PC.el.$mainNav.classList.add("mobile");
			PC.el.$mainMenu.classList.remove("desktop");
			PC.el.$body.prepend(PC.el.$mainMenu);
		} else {
			// Desktop
			log.info("Desktop");
			PC.config.mobile = false;
			PC.el.$mainMenu.classList.add("desktop");
			PC.el.$mainNav.prepend(PC.el.$mainMenu);
			//PC.el.$mainNav.classList.remove("mobile");

			// Close mobile menu if open
			PC.el.$body.classList.remove("nav-open");
			PC.el.$mainMenu_Hamburger.classList.remove("opened");
			PC.el.$mainMenu_Hamburger.setAttribute("aria-expanded", false);
		}

		// Update height of submenu
		/*if (typeof PC.el.$mainMenu_Dropdown !== 'undefined') {
			for (const element of PC.el.$mainMenu_Dropdown) {
				//log.info(element);
		
				let anchor = element.getElementsByTagName("a")[0];
				let submenu = anchor.nextSibling;
				submenu.classList.remove("closed");
				let submenuHeight = submenu.offsetHeight;		
				submenu.classList.add("closed");
				submenu.style.height = submenuHeight+"px";
				submenu.dataset.height = submenuHeight+"px";
				log.info(submenu.style.height);
			};
		}*/
	}
	resizeActions();
	optimizedResize().add(resizeActions);
});