function optimizedResize() {

	var callbacks = [],
		running = false;

	// fired on resize event
	function resize() {

		if (!running) {
			running = true;

			if (window.requestAnimationFrame) {
				window.requestAnimationFrame(runCallbacks);
			} else {
				setTimeout(runCallbacks, 66);
			}
		}

	}

	// run the actual callbacks
	function runCallbacks() {
		callbacks.forEach(function(callback) {
			callback();
		});
		running = false;
	}

	// adds callback to loop
	function addCallback(callback) {
		if (callback) {
			callbacks.push(callback);
		}
	}

	// remove callback from loop
	function removeCallback(callback) {

		if (callback) {
			callbacks.pull(callback);
		}

	}

	return {
		// public method to add additional callback
		add: function(callback) {
			if (!callbacks.length) {
				window.addEventListener('resize', resize);
			}
			addCallback(callback);
		},
		// public method to remove specified callback
		remove: function(callback) {
			removeCallback(callback);
		}
	}
};

export { optimizedResize }